import { fetchWrapperPortal } from "./fetch";

export const getIngestServers = async (userId) =>
  await fetchWrapperPortal({
    url: `system/ingest/`,
    method: `GET`,
    addBearer: true,
  });

export const getFrontendVersion = async () =>
  await fetchWrapperPortal({
    url: `system/web_frontend_version/`,
    method: `GET`,
    addBearer: true,
  });

export const getChannels = async () =>
  await fetchWrapperPortal({
    url: `system/channels/`,
    method: `GET`,
    addBearer: true,
  });

export const getWebhookAccess = async () =>
  await fetchWrapperPortal({
    url: `system/webhook-access/`,
    method: `GET`,
    addBearer: true,
  });

export const getOrgs = async (id) =>
  await fetchWrapperPortal({
    url: `system/orgs/`,
    method: `GET`,
    addBearer: true,
  });

export const getOrg = async (id) =>
  await fetchWrapperPortal({
    url: `system/orgs/?id=${id}`,
    method: `GET`,
    addBearer: true,
  });

export const createOrg = async (org) => {
  await fetchWrapperPortal({
    url: `system/orgs/`,
    method: `POST`,
    addBearer: true,
    body: JSON.stringify(org),
  });
};

export const updateOrg = async (id, update) => {
  await fetchWrapperPortal({
    url: `system/orgs/${id}`,
    method: `PUT`,
    addBearer: true,
    body: JSON.stringify(update),
  });
};

// Rules API endpoints
export const getRules = async () =>
  await fetchWrapperPortal({
    url: `rules/`,
    method: `GET`,
    addBearer: true
  });

export const createRule = async (ruleData) =>
  await fetchWrapperPortal({
    url: `rules/`,
    method: `POST`,
    body: JSON.stringify(ruleData),
    addBearer: true
  });

export const updateRule = async (ruleId, ruleData) =>
  await fetchWrapperPortal({
    url: `rules/${ruleId}`,
    method: `PUT`,
    body: JSON.stringify(ruleData),
    addBearer: true
  });

export const deleteRule = async (ruleId) =>
  await fetchWrapperPortal({
    url: `rules/${ruleId}`,
    method: `DELETE`,
    addBearer: true
  });
